import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import AuthContext from '../context/auth/authContext';
import Loader from '../Components/Loader/Loader';

export const PublicRoute = ({ component: Component, ...rest }) => {
  const authContext = useContext(AuthContext);
  const { isAuthenticated, loading } = authContext;
  if (loading) {
    return <Loader />;
  }
  return <Route {...rest} render={(props) => (!isAuthenticated ? <Component {...props} /> : <Redirect to="/" />)} />;
};
