import { Row, Spin } from 'antd';
import React from 'react';

const Loader = () => (
  <Row style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <Spin size="large" />
  </Row>
);

export default Loader;
