import React, { Suspense } from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';

import { PrivateRoute } from '../../hoc/ProtectedRoute';
import Loader from '../../Components/Loader/Loader';

const UsersPage = React.lazy(() => import('./UsersPage'));
const UserDetails = React.lazy(() => import('./UserDetails/UserDetails'));
const SubscribersPage = React.lazy(() => import('../Reporting/SubscribersPage'));
const ExceededStreamingMinutes = React.lazy(() => import('../Users/ExceededStreamingMinutes'));

const Users = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Suspense fallback={<Loader />}>
        <PrivateRoute exact path={`${path}`} component={UsersPage} />
        <PrivateRoute exact path={`${path}/user-details`} component={UserDetails} />
        <PrivateRoute exact path={`${path}/streaming/exceeded-minutes`} component={ExceededStreamingMinutes} />
        <PrivateRoute exact path={`${path}/reports/subscribers`} component={SubscribersPage} />
        <PrivateRoute exact path={`${path}/:id`} component={UserDetails} />
      </Suspense>
    </Switch>
  );
};

export default Users;
