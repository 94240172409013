import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Badge, Layout, Menu, Typography } from 'antd';
import AuthContext from '../../context/auth/authContext';
import DataContext from '../../context/data/dataContext';

const { Header } = Layout;

const HeaderComponent = () => {
  const authContext = useContext(AuthContext);
  const dataContext = useContext(DataContext);
  const { num_blocked_users, number_of_double_tickets_active } = dataContext;
  const { logout, user } = authContext;
  const [selectedKey, setSelectedKey] = useState('1');

  const onClick = () => {
    logout();
  };
  const renderContent = () => {
    if (user && ['tmc-super-admin', 'tmc-admin', 'sky-legal', 'sky-admin', 'sky-jugen'].includes(user.role))
      return (
        <Menu.Item key="1" onClick={() => setSelectedKey('1')}>
          Content
          <Link to="/content" />
        </Menu.Item>
      );
  };

  const renderUsers = () =>
    user &&
    ['tmc-super-admin', 'tmc-admin', 'sky-customer', 'sky-tech'].includes(user.role) && (
      <Menu.Item key="4" onClick={() => setSelectedKey('4')}>
        {num_blocked_users ? 'Users' : 'Users'}

        <Link to="/users" />
      </Menu.Item>
    );

  const renderBadge = (number) => {
    if (number) {
      return <Badge count={number} overflowCount={99} />;
    }
  };

  return (
    <Header>
      <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['/']}>
        {/*Content*/}
        {renderContent()}

        {user && ['tmc-super-admin', 'tmc-admin'].includes(user.role) && (
          <>
            <Menu.Item key="2" onClick={() => setSelectedKey('2')}>
              Products
              <Link to="/products" />
            </Menu.Item>
            <Menu.Item key="3" onClick={() => setSelectedKey('3')}>
              Pflege
              <Link to="/pflege" />
            </Menu.Item>
          </>
        )}

        {/*Users*/}
        {renderUsers()}
        {user && ['tmc-super-admin', 'tmc-admin'].includes(user.role) && (
          <>
            {/*<Menu.Item key="11">*/}
            {/*  C Users*/}
            {/*  {renderBadge(num_blocked_users)}*/}
            {/*  <Link to="/cusers"/>*/}
            {/*</Menu.Item>*/}
            {/*<Menu.Item key="15">*/}
            {/*  Atos Login*/}
            {/*  {renderBadge(number_of_missing_payloads)}*/}
            {/*  <Link to="/missing-payloads"/>*/}
            {/*</Menu.Item>*/}
          </>
        )}

        {user && user.role === 'tmc-super-admin' && (
          <Menu.Item key="5" onClick={() => setSelectedKey('5')}>
            Admin
            <Link to="/admin" />
          </Menu.Item>
        )}

        {user && ['tmc-super-admin', 'tmc-admin'].includes(user.role) && (
          <Menu.Item key="6" onClick={() => setSelectedKey('6')}>
            Reporting
            <Link to="/reporting" />
          </Menu.Item>
        )}
        {user && ['tmc-super-admin'].includes(user.role) && (
          <Menu.Item key="9" onClick={() => setSelectedKey('9')}>
            Cache
            <Link to="/admin/cache" />
          </Menu.Item>
        )}

        {user &&
          (['tmc-super-admin', 'sky-subscribers-admin'].includes(user.role) ||
            user.email === 'Felix.Witthueser@sky.at') && (
            <Menu.Item key="7" onClick={() => setSelectedKey('7')}>
              Subscribers
              <Link to="/users/reports/subscribers" />
            </Menu.Item>
          )}

        {user && user.role === 'tmc-super-admin' && (
          <Menu.Item key="11" onClick={() => setSelectedKey('11')}>
            <Badge count={number_of_double_tickets_active} offset={[8, -8]}>
              <p style={{ color: selectedKey === '11' ? 'white' : 'hsla(0, 0%, 100%, .65)' }}>Double Active Ticket</p>
              <Link to="/double-active-ticket" />
            </Badge>
          </Menu.Item>
        )}

        <Menu.Item key="8" onClick={onClick}>
          Logout
        </Menu.Item>

        <Menu.Item
          key="10"
          onClick={() => setSelectedKey('10')}
          style={{
            marginLeft: 'auto',
            backgroundColor: '#001529',
            cursor: 'default',
          }}
        >
          <Typography
            style={{
              color: 'white',
            }}
            strong="true"
          >
            {user.email}
          </Typography>
        </Menu.Item>
      </Menu>
    </Header>
  );
};

export default HeaderComponent;
