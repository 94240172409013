import { useCallback, useContext, useState } from 'react';
import axios from 'axios';
import AuthContext from '../context/auth/authContext';
import { useHistory } from 'react-router-dom';
import { isObjectEmpty } from '../utils';
import { OptionsModel } from './types';

export const useApi = () => {
  const [isLoading, setIsLoading] = useState(false);
  // const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState<string | null>();
  const authContext = useContext(AuthContext);
  const { removeUser } = authContext;
  const history = useHistory();
  const baseUrl = process.env.REACT_APP_BASE_API_URL;

  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  const token = localStorage.getItem('token');
  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  const sendRequest = useCallback(
    async (
      url: string,
      options: OptionsModel = {
        method: '',
        body: {},
        params: {},
        headers: {},
      }
    ) => {
      clearError();
      setIsLoading(true);
      if (options.headers && !isObjectEmpty(options.headers)) {
        headers[`${options.headers.key}`] = `${options.headers.value}`;
      }
      try {
        const response = await axios({
          url,
          method: options.method || 'get',
          baseURL: `${baseUrl}/admin-api`,
          data: options.body,
          params: options.params,
          headers,
        });
        setIsLoading(false);
        return response;
      } catch (err) {
        setIsLoading(false);
        if (err.message === 'Request failed with status code 401') {
          removeUser();
          localStorage.removeItem('token');
          history.push('/login');
        }
        setError('Something went wrong');
        return err?.response?.data;
      }
    }, // eslint-disable-next-line
    [token]
  );

  const clearError = () => {
    setError(null);
  };

  return { isLoading, error, sendRequest, clearError };
};
