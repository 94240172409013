import React from 'react';
import styles from './MinuteGraphLegend.module.scss';

const MinuteGraphLegend: React.FC = () => {
  return (
    <div className={styles.legend}>
    <div>
      <span className={`${styles.circle} ${styles.red}`}></span> High discrepancy (15+%)
  </div>
  <div>
  <span className={`${styles.circle} ${styles.yellow}`}></span> Medium discrepancy (10-15+%)
  </div>
  <div>
  <span className={`${styles.circle} ${styles.green}`}></span> Low discrepancy (5-10+%)
  </div>
  <div>
  <span className={`${styles.circle} ${styles.gray}`}></span>Avg
  </div>
  </div>
);
};

export default MinuteGraphLegend;
