import React from 'react';
import dayjs from 'dayjs';
import styles from './MinuteModal.module.scss';
import useMinuteGraphRangeQuery, { Daum, Minutes } from '../../../../../hooks/query/minutes/useMinuteGraphRangeQuery';
import { dateFormatMinuteGraph, selectClass, selectColor } from '../MinuteGraph';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import MinuteGraphLegend from '../MinuteGraphLegend/MinuteGraphLegend';
export const GRAPH_MODAL_DAY_CATEGORIES = ['Today', 'Last week', 'A month ago', 'A year ago'];
interface MinuteModalProps {
  referentDay: dayjs.Dayjs;
}

const MinuteModal: React.FC<MinuteModalProps> = ({ referentDay }) => {
  const periods = {
    today: referentDay,
    lastWeek: referentDay.subtract(1, 'week'),
    oneMonthAgo: referentDay.subtract(1, 'month'),
    oneYearAgo: referentDay.subtract(1, 'year'),
  };

  const graphDataToday = useMinuteGraphRangeQuery({
    from: periods.today.format(dateFormatMinuteGraph),
    to: periods.today.add(1, 'days').format(dateFormatMinuteGraph),
  });

  const graphDataLastWeek = useMinuteGraphRangeQuery({
    from: periods.lastWeek.format(dateFormatMinuteGraph),
    to: periods.lastWeek.add(1, 'days').format(dateFormatMinuteGraph),
  });

  const graphDataOneMonthAgo = useMinuteGraphRangeQuery({
    from: periods.oneMonthAgo.format(dateFormatMinuteGraph),
    to: periods.oneMonthAgo.add(1, 'days').format(dateFormatMinuteGraph),
  });

  const graphDataOneYearAgo = useMinuteGraphRangeQuery({
    from: periods.oneYearAgo.format(dateFormatMinuteGraph),
    to: periods.oneYearAgo.add(1, 'days').format(dateFormatMinuteGraph),
  });

  if (!graphDataToday.graphData || !graphDataLastWeek.graphData || !graphDataOneMonthAgo.graphData || !graphDataOneYearAgo.graphData) {
    return null;
  }

  const elements = [
    graphDataToday.graphData.data.minutes[0],
    graphDataLastWeek.graphData.data.minutes[0],
    graphDataOneMonthAgo.graphData.data.minutes[0],
    graphDataOneYearAgo.graphData.data.minutes[0],
  ];

  const colors = elements.map((element: Minutes) => selectColor(element));

  const graphConfig: { options: ApexOptions; series: { name: string; data: number[] }[] } = {
    options: {
      chart: { id: 'minute-graph-modal' },
      xaxis: {
        categories: GRAPH_MODAL_DAY_CATEGORIES,
      },
      plotOptions: { bar: { distributed: true } },
      colors: colors,
      legend: { show: false },
      tooltip: {
        custom: ({ series, seriesIndex, dataPointIndex, w }) => {
          const data = series[seriesIndex][dataPointIndex];
          const date = elements[dataPointIndex].date;
          return `<div class="${styles.toolltip}">
            <span class="${styles.title}">Date: ${dayjs(date).format('DD MMM YYYY')}</span><br/>
            <div class="${styles.minutes}">
              <span>Minutes:</span>
              <div>
                <span class="${styles.circle} ${selectClass(elements[dataPointIndex])}"></span>
                <span>${data}</span>
              </div>
            </div>
          </div>`;
        },
      },
      dataLabels: { enabled: false },
    },
    series: [
      {
        name: 'Minutes: ',
        data: elements.map((element: Minutes) => element.minutes),
      },
    ],
  };

  return (
    <div className={styles.modalContainer}>
      <div className={styles.graph}>
        <Chart options={graphConfig.options} series={graphConfig.series} type="bar" height={400} />
      </div>
      <MinuteGraphLegend />
    </div>
  );
};

export default MinuteModal;
