import React, { useEffect, useState } from 'react';
import { useApi } from '../../hooks/useApi';
import { useHistory } from 'react-router-dom';
import { Divider, Pagination, Row, Space, Table, Tag } from 'antd';

const MissinPayload = () => {
  const usersApi = useApi();
  const { sendRequest: fetchPayloads } = usersApi;
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState();

  const getPayload = async () => {
    const responseData = await fetchPayloads(`/user/missing-payload?page=${currentPage}`);
    setMeta(responseData.data.meta);
    setData(responseData.data.data);
  };

  const columns = [
    {
      title: 'User ID',
      dataIndex: 'user_id',
      key: 'user_id',
      width: 80,
    },
    {
      title: 'Atos',
      dataIndex: 'atos_response',
      key: 'atos_response',
    },
    {
      title: 'Customer no.',
      dataIndex: 'customer_number',
      key: 'customer_number',
      width: 100,
    },
    {
      title: 'Created at',
      dataIndex: 'created_at',
      key: 'created_at',
      width: 100,
    },
    {
      title: 'Missing data',
      dataIndex: 'missing_data',
      key: 'missing_data',
      width: 100,
    },
  ];

  useEffect(() => {
    getPayload();
  }, [currentPage]);
  const onChange = (e) => {
    console.log(e);
    setCurrentPage(e);
  };
  return (
    <div>
      <Row justify="end">
        {meta && (
          <Pagination
            pageSize={meta.per_page}
            current={currentPage}
            showSizeChanger={false}
            onChange={onChange}
            total={meta.total}
          />
        )}
      </Row>
      <Divider />
      <Table
        loading={usersApi.isLoading}
        columns={columns}
        dataSource={data}
        size="small"
        pagination={false}
        rowClassName="bigTable"
      />
      <Divider />
      <Row justify="end">
        {meta && (
          <Pagination
            pageSize={meta.per_page}
            current={currentPage}
            showSizeChanger={false}
            onChange={onChange}
            total={meta.total}
          />
        )}
      </Row>
    </div>
  );
};

export default MissinPayload;
