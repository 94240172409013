import React, { useEffect, useState, useContext } from 'react';
import { Table, Tag, Space, Input, Divider, Row, Pagination } from 'antd';
import { useHistory } from 'react-router-dom';
import { useApi } from '../../../hooks/useApi';
import AuthContext from '../../../context/auth/authContext';
import moment from 'moment';

const { Search } = Input;
const Cusers = () => {
  const usersApi = useApi();
  const { sendRequest: fetchUsers } = usersApi;
  const history = useHistory();
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [term, setTerm] = useState(null);
  const authContext = useContext(AuthContext);
  const { user } = authContext;

  useEffect(() => {
    if (user && user.role == 'sky-legal') {
      history.push('/notAllowed');
    }
  }, [user]);

  const getUsers = async () => {
    const responseData = await fetchUsers(`/user/index?filter[state]=C&filter[role.name]=user&page=${currentPage}`);
    setMeta(responseData.data.meta);
    setData(responseData.data.data);
  };

  useEffect(() => {
    getUsers();
  }, [currentPage]);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Registered',
      dataIndex: 'registered_at',
      key: 'registered_at',
      render: (date) => (date ? moment(date, 'YYYY-MM-DD HH:ss:mm').format('DD-MM-YYYY HH:ss:mm') : ''),
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (status) => (
        <>
          {status === 'Active' ? (
            <Tag color="green" key={status}>
              {status.toUpperCase()}
            </Tag>
          ) : (
            <Tag color="red" key={status}>
              {status.toUpperCase()}
            </Tag>
          )}
        </>
      ),
    },
    {
      title: 'View',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <a onClick={() => history.push('/users/' + record.id)}>View</a>
        </Space>
      ),
    },
  ];

  const onChange = (e) => {
    console.log(e);
    setCurrentPage(e);
  };

  const search = async () => {
    const responseData = await fetchUsers(`/user/search?q=${term}`);
    setMeta(responseData.data.meta);
    setData(responseData.data.data);
  };

  function debounce(func, timeout = 700) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }
  useEffect(() => {
    if (term !== null && term.length > 2) {
      debounce(search());
    } else if (term === '') {
      getUsers();
    }
  }, [term]);

  const onSearch = (e) => {
    setTerm(e.target.value);
  };
  return (
    <div>
      <h1>C Users</h1>
      {/*<Search*/}
      {/*  style={{marginBottom: 30}}*/}
      {/*  placeholder="Search Users"*/}
      {/*  allowClear*/}
      {/*  size="large"*/}
      {/*  onChange={onSearch}*/}
      {/*/>*/}
      <Row justify="end">
        {meta && (
          <Pagination current={currentPage} showSizeChanger={false} onChange={onChange} total={meta.last_page} />
        )}
      </Row>
      <Divider />
      <Table loading={usersApi.isLoading} columns={columns} dataSource={data} size="small" pagination={false} />
      <Divider />
      <Row justify="end">
        {meta && (
          <Pagination current={currentPage} showSizeChanger={false} onChange={onChange} total={meta.last_page} />
        )}
      </Row>
    </div>
  );
};

export default Cusers;
