import React, { FC } from 'react';
import useDoubleActiveTicketQuery from '../../hooks/query/useDoubleActiveTicket';
import { Table } from 'antd';
import Loader from '../../Components/Loader/Loader';
import { Link } from 'react-router-dom';
import { DoubleActiveTicketModel } from '../../hooks/models/DoubleActiveTicketModel';

interface DoubleActiveTicketI {}

const DoubleActiveTicket: FC<DoubleActiveTicketI> = () => {
  const { doubleActiveTicketData, isDoubleActiveTicketLoading } = useDoubleActiveTicketQuery();

  console.log(doubleActiveTicketData);

  const columns = [
    {
      title: 'User ID',
      dataIndex: ['user', 'id'],
      key: 'user.id',
      render: (text: string, record: DoubleActiveTicketModel) => (
        <Link to={`/users/` + record.user.id}>
          <div>{text}</div>
        </Link>
      ),
    },
    {
      title: 'Username',
      dataIndex: ['user', 'username'],
      key: 'user.username',
    },
  ];

  if (isDoubleActiveTicketLoading) {
    return <Loader />;
  }

  return (
    <div>
      <Table pagination={false} columns={columns} dataSource={doubleActiveTicketData} bordered />
    </div>
  );
};

export default DoubleActiveTicket;
