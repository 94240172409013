import React, { useContext } from 'react';
import { Breadcrumb, Layout } from 'antd';
import HeaderComponent from '../Components/Header/Header';
import SiderComponent from '../Components/Sidebar/SiderComponent';
import { Link, useHistory } from 'react-router-dom';
import AuthContext from '../context/auth/authContext';

const { Content } = Layout;

const MainView = (props) => {
  const history = useHistory();
  const { user } = useContext(AuthContext);

  const path = history.location.pathname;

  let content = path.startsWith('/content');
  let users = path.startsWith('/users');
  let logout = path.startsWith('/logout');
  let login = path.startsWith('/login');
  let admin = path.startsWith('/admin');
  let root = path === '/';

  if (user && user.role !== 'tmc-super-admin' && admin) {
    history.push('/notAllowed');
  }

  if (
    user &&
    !['tmc-super-admin', 'tmc-admin'].includes(user.role) &&
    !content &&
    !users &&
    !logout &&
    !login &&
    !root
  ) {
    history.push('/notAllowed');
  }

  const routes = [
    {
      path: path,
      breadcrumbName: `${path}`.substring(1).toUpperCase(),
    },
  ];

  function itemRender(route, params, routes, paths) {
    const last = routes.indexOf(route) === routes.length - 1;
    return last ? (
      <span style={{ color: '#b4b4b4', fontSize: '12px', letterSpacing: '0.08em' }}>{route.breadcrumbName}</span>
    ) : (
      <Link to={paths.join('/')}>{route.breadcrumbName}</Link>
    );
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <HeaderComponent />
      <Layout>
        <SiderComponent />
        <Layout style={{ padding: '30px 24px 24px' }}>
          <Breadcrumb style={{ margin: '16px 0' }} itemRender={itemRender} routes={routes}></Breadcrumb>
          <Content
            id="layout"
            className="site-layout-background"
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
            }}
          >
            {props.children}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default MainView;
