import React, { Suspense } from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';

import { PrivateRoute } from '../../hoc/ProtectedRoute';
import Loader from '../../Components/Loader/Loader';
import MinuteGraph from './Dashboard/Minutes/MinuteGraph';

const Actors = React.lazy(() => import('./Actors'));
const Categories = React.lazy(() => import('./Categories'));
const Distributors = React.lazy(() => import('./Distributors'));
const ContentAPI = React.lazy(() => import('./Import/API'));
const XML = React.lazy(() => import('./Import/XML'));
const EditContent = React.lazy(() => import('./Overview/Edit'));
const OverviewContent = React.lazy(() => import('./Overview/Overview'));
const Dashboard = React.lazy(() => import('./Dashboard/Dashboard'));
const ReleaseCalendar = React.lazy(() => import('./ReleaseCalendar'));
const Pools = React.lazy(() => import('./Screening/Pools'));
const RawContent = React.lazy(() => import('./Screening/Raw'));
const Studios = React.lazy(() => import('./Studios'));
const VideoUploadPage = React.lazy(() => import('./VideoUpload/VideoUploadPage'));
const Finance = React.lazy(() => import('./Dashboard/Finance/Finance'));
const Traffic = React.lazy(() => import('./Dashboard/Traffic/Traffic'));
const Errors = React.lazy(() => import('./Dashboard/Errors/Errors'));

const Content = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Suspense fallback={<Loader />}>
        <PrivateRoute exact path={`${path}`} component={OverviewContent} />
        <PrivateRoute exact styles path={`${path}/dashboard`} component={Dashboard} />
        <PrivateRoute exact path={`${path}/dashboard/finance`} component={Finance} />
        <PrivateRoute exact path={`${path}/dashboard/traffic`} component={Traffic} />
        <PrivateRoute exact path={`${path}/dashboard/errors`} component={Errors} />
        <PrivateRoute exact path={`${path}/dashboard/minutes`} component={MinuteGraph} />
        <PrivateRoute exact path={`${path}/overview`} component={OverviewContent} />
        <PrivateRoute exact path={`${path}/edit/:id`} component={EditContent} />
        <PrivateRoute exact path={`${path}/release-calendar`} component={ReleaseCalendar} />
        <PrivateRoute exact path={`${path}/categories`} component={Categories} />
        <PrivateRoute exact path={`${path}/import/xml`} component={XML} />
        <PrivateRoute exact path={`${path}/import/api`} component={ContentAPI} />
        <PrivateRoute exact path={`${path}/import/manual`} component={VideoUploadPage} />
        <PrivateRoute exact path={`${path}/studios`} component={Studios} />
        <PrivateRoute exact path={`${path}/distributors`} component={Distributors} />
        <PrivateRoute exact path={`${path}/actors`} component={Actors} />
        <PrivateRoute exact path={`${path}/screening/raw`} component={RawContent} />
        <PrivateRoute exact path={`${path}/screening/pools`} component={Pools} />
      </Suspense>
    </Switch>
  );
};

export default Content;
