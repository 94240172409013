import React, { Suspense } from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';

import { PrivateRoute } from '../../hoc/ProtectedRoute';
import Loader from '../../Components/Loader/Loader';

const ReportingPage = React.lazy(() => import('./ReportingPage'));
const TotalUserBalance = React.lazy(() => import('./TotalUserBalance'));
const Mbmv2 = React.lazy(() => import('./MbmV2Grouped'));
const Mbm = React.lazy(() => import('./Mbm'));
const EpochPage = React.lazy(() => import('./Epoch'));
const Chrons = React.lazy(() => import('./Chrons'));
const SubscribersPage = React.lazy(() => import('./SubscribersPage'));

const Reporting = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Suspense fallback={<Loader />}>
        <PrivateRoute exact path={`${path}`} component={ReportingPage} />
        <PrivateRoute exact path={`${path}/chrons`} component={Chrons} />
        <PrivateRoute exact path={`${path}/total-user-balance`} component={TotalUserBalance} />
        <PrivateRoute exact path={`${path}/epoch`} component={EpochPage} />
        <PrivateRoute exact path={`${path}/mbm1`} component={Mbm} />
        <PrivateRoute exact path={`${path}/mbm1v2`} component={Mbmv2} />
        <PrivateRoute exact path={`${path}/subscribers`} component={SubscribersPage} />
      </Suspense>
    </Switch>
  );
};

export default Reporting;
