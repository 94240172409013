import {
  FormatPainterOutlined,
  PictureOutlined,
  SoundOutlined,
  DropboxOutlined,
  CopyOutlined,
  WalletOutlined,
} from '@ant-design/icons';
import React from 'react';
import { Link } from 'react-router-dom';

export const pflegeMenuItems = [
  {
    key: '1',
    icon: <FormatPainterOutlined />,
    label: <Link to="/pflege">Themes</Link>,
  },
  {
    key: '2',
    icon: <PictureOutlined />,
    label: <Link to="/pflege/teasers">Teasers (Q)</Link>,
  },
  {
    key: '3',
    icon: <SoundOutlined />,
    label: <Link to="/pflege/promotions">Promotions</Link>,
  },
  {
    key: '4',
    icon: <PictureOutlined />,
    label: <Link to="/pflege/banners">Banners</Link>,
    children: [
      { key: '4.1', label: <Link to="/pflege/banners/com">.com</Link> },
      { key: '4.2', label: <Link to="/pflege/banners/stb">stb</Link> },
    ],
  },
  {
    key: '5',
    icon: <DropboxOutlined />,
    label: <Link to="/pflege/product-promotions">Product Promotions</Link>,
  },
  {
    label: 'Pages',
    key: 'Pages',
    icon: <CopyOutlined />,
    children: [
      { key: '6', label: <Link to="/pflege/pages/static">Static</Link> },
      { key: '7', label: <Link to="/pflege/pages/maintenance">Maintenance</Link> },
    ],
  },
  {
    key: '8',
    icon: <WalletOutlined />,
    label: <Link to="/pflege/stb-promotions">STB Promotions</Link>,
  },
];
