import React, { Suspense } from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';

import { PrivateRoute } from '../../hoc/ProtectedRoute';
import Loader from '../../Components/Loader/Loader';

const ProductsPage = React.lazy(() => import('./ProductsPage'));
const AddNewProduct = React.lazy(() => import('./AddNewProduct'));

const Products = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Suspense fallback={<Loader />}>
        <PrivateRoute exact path={`${path}`} component={ProductsPage} />
        <PrivateRoute exact path={`${path}/add-new`} component={AddNewProduct} />
      </Suspense>
    </Switch>
  );
};

export default Products;
