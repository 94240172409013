import React, { useContext } from 'react';
import { Badge, Menu } from 'antd';
import { Link } from 'react-router-dom';
import {
  FileDoneOutlined,
  ClockCircleOutlined,
  CreditCardOutlined,
  NumberOutlined,
  DollarCircleFilled,
  LoginOutlined,
  UserOutlined,
  FileMarkdownOutlined,
} from '@ant-design/icons';
import DataContext from '../../context/data/dataContext';

const ReportingLinks = () => {
  const dataContext = useContext(DataContext);
  const { num_blocked_users, number_of_missing_payloads } = dataContext;
  const renderBadge = (number) => {
    if (number) {
      return <Badge count={number} overflowCount={99} />;
    }
  };
  return (
    <Menu
      mode="inline"
      defaultSelectedKeys={['1']}
      defaultOpenKeys={['sub1']}
      style={{ height: '100%', borderRight: 0 }}
      theme="dark"
    >
      <Menu.Item key="1" icon={<FileDoneOutlined />}>
        All
        <Link to="/reporting" />
      </Menu.Item>
      <Menu.Item key="2" icon={<CreditCardOutlined />}>
        MBM1 Stats
        <Link to="/reporting/mbm1" />
      </Menu.Item>
      <Menu.Item key="8" icon={<FileMarkdownOutlined />}>
        Ludonomics
        <Link to="/reporting/mbm1v2" />
      </Menu.Item>
      <Menu.Item key="3" icon={<NumberOutlined />}>
        Epoch
        <Link to="/reporting/epoch" />
      </Menu.Item>
      <Menu.Item key="9" icon={<NumberOutlined />}>
        Worldline
        <Link to="/reporting/worldline" />
      </Menu.Item>
      <Menu.Item key="4" icon={<ClockCircleOutlined />}>
        Chrons
        <Link to="/reporting/chrons" />
      </Menu.Item>
      <Menu.Item key="5" icon={<DollarCircleFilled />}>
        Total Users Balance
        <Link to="/reporting/total-user-balance" />
      </Menu.Item>
      <Menu.Item key="6" icon={<LoginOutlined />}>
        Atos Login
        {/*renderBadge(number_of_missing_payloads)*/}
        <Link to="/missing-payloads" />
      </Menu.Item>
      <Menu.Item key="7" icon={<UserOutlined />}>
        C Users
        {/*renderBadge(number_of_missing_payloads)*/}
        <Link to="/cusers" />
      </Menu.Item>
    </Menu>
  );
};

export default ReportingLinks;
