import React, { useCallback, useState } from 'react';
import { Layout } from 'antd';
import { useLocation } from 'react-router-dom';
import ContentLinks from './ContentLinks';
import ProductsLinks from './ProductsLinks';
import { PflegeLinks } from './PfledgeLinks';
import ReportingLinks from './ReportingLinks';
import UsersLinks from "./UsersLinks";

const { Sider } = Layout;

const SiderComponent = () => {
  let location = useLocation();
  let content = location.pathname.startsWith('/content') || location.pathname === '/';
  let products = location.pathname.startsWith('/products');
  let product = location.pathname.startsWith('/product');
  let pflege = location.pathname.startsWith('/pflege');
  let users = location.pathname.startsWith('/users');
  let cusers = location.pathname.startsWith('/cusers');
  let admin = location.pathname.startsWith('/admin');
  let atos = location.pathname.startsWith('/missing-payloads');
  let reporting = location.pathname.startsWith('/reporting');
  const [colapse, setColapse] = useState(true);

  const renderMenu = useCallback(() => {
    if (content) {
      return <ContentLinks />;
    } else if (products) {
      return <ProductsLinks />;
    } else if (product) {
      return <ProductsLinks />;
    } else if (pflege) {
      return <PflegeLinks />;
    } else if (users) {
      return <UsersLinks />;
    } else if (reporting || cusers || atos) {
      console.log('rendering cusers', cusers);
      return <ReportingLinks />;
    }
    return <ContentLinks />;
  }, [atos, content, cusers, pflege, product, products, reporting, users]);

  const onCollapse = () => {
    setColapse(!colapse);
  };
  if ( admin) {
    return null;
  }
  return (
    <Sider collapsible collapsed={colapse} onCollapse={onCollapse}>
      {renderMenu()}
    </Sider>
  );
};

export default SiderComponent;
