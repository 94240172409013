import React, { useEffect, useState } from 'react';
import { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';
import styles from './MinuteGraph.module.scss';
import useMinuteGraphRangeQuery, { Daum, Minutes } from '../../../../hooks/query/minutes/useMinuteGraphRangeQuery';
import { Modal, Select } from 'antd';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import MinuteModal from './MinuteModal/MinuteModal';
import MinuteGraphLegend from './MinuteGraphLegend/MinuteGraphLegend';

dayjs.extend(weekOfYear);

const { Option } = Select;
export const dateFormatMinuteGraph = 'YYYY-MM-DD';

export const selectColor = (element: Minutes) => {
  if (parseInt(element.difference) < 5) {
    return '#BBBBBB'; // gray
  } else if (parseInt(element.difference) >= 5 && parseInt(element.difference) < 10) {
    return '#44A048'; // green
  } else if (parseInt(element.difference) >= 10 && parseInt(element.difference) < 15) {
    return '#FAB43B'; // orange
  } else {
    return '#FF0000'; // red
  }
};

export const selectClass = (element: Minutes) => {
  if (parseInt(element.difference) < 5) {
    return styles.gray; // gray
  } else if (parseInt(element.difference) >= 5 && parseInt(element.difference) < 10) {
    return styles.green;
  } else if (parseInt(element.difference) >= 10 && parseInt(element.difference) < 15) {
    return styles.yellow;
  } else {
    return styles.red;
  }
};

const getOptions = (selectedYear: number) => {
  const weeksInYear = Array.from(
    { length: selectedYear == dayjs().year() ? dayjs().year(selectedYear).week() - 1 : 52 },
    (_, i) => `Week ${i + 1}`
  );

  return [
    { value: 'last28days', label: 'Last 28 days' },
    { value: 'lastweek', label: 'Last week' },
    ...weeksInYear.map((week, index) => ({
      value: `${index + 1}-weekthisyear`,
      label: `${week} of ${selectedYear}`,
    })),
  ];
};

const getYears = () => {
  return Array.from({ length: 11 }, (_, i) => new Date().getFullYear() - i);
};

const MinuteGraph: React.FC = () => {
  const [startDate, setStartDate] = useState<dayjs.Dayjs>(dayjs().subtract(28, 'days'));
  const [endDate, setEndDate] = useState<dayjs.Dayjs>(dayjs().subtract(1, 'days'));
  const [selectedRange, setSelectedRange] = useState('last28days');
  const [minuteModal, setMinuteModal] = useState(false);
  const [minuteModalDay, setMinuteModalDay] = useState<dayjs.Dayjs>(dayjs());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  const { graphData } = useMinuteGraphRangeQuery({
    from: startDate.format(dateFormatMinuteGraph),
    to: endDate.format(dateFormatMinuteGraph),
  });

  useEffect(() => {
    if (selectedRange === 'last28days') {
      setStartDate(dayjs().subtract(28, 'days'));
      setEndDate(dayjs().subtract(1, 'days'));
      setSelectedYear(dayjs().year());
      return;
    }
    if (selectedRange === 'lastweek') {
      setStartDate(dayjs().subtract(7, 'days'));
      setEndDate(dayjs().subtract(1, 'days'));
      setSelectedYear(dayjs().year());
    }

    if (selectedRange.includes('weekthisyear')) {
      const weekNumber = parseInt(selectedRange.split('-')[0]);
      const startOfWeek = dayjs().year(selectedYear).week(weekNumber).startOf('week').add(1, 'day');
      const endOfWeek = dayjs().year(selectedYear).week(weekNumber).endOf('week').add(1, 'day');
      if(endOfWeek.isAfter(dayjs())){
        setSelectedRange('1-weekthisyear');
        return;
      }
      setStartDate(startOfWeek);
      setEndDate(endOfWeek);
    }
  }, [selectedRange, selectedYear]);

  if (!graphData) {
    return <div>Loading...</div>;
  }

  const colors = graphData.data.minutes.map(selectColor);
  const annotations = {
    xaxis: graphData.data.minutes
      .map((element: Minutes, index: number) => {
        if (index % 7 === 0 && index) {
          const weekNumber = Math.floor(index / 7) + 1;
          return {
            x: dayjs(element.date).format(dateFormatMinuteGraph),
            borderColor: '#00E396',
            label: {
              borderColor: '#00E396',
              style: {
                color: '#fff',
                background: '#00E396',
              },
              text: `${weekNumber} week`,
            },
          };
        }
        return null;
      })
      .filter((annotation) => annotation !== null) as ApexAnnotations['xaxis'],
  };

  const chartOptions: ApexOptions = {
    chart: {
      toolbar: { show: false },
      id: 'basic-bar',
      events: {
        dataPointSelection: (event, chartContext, config) => {
          const xAxisLabel = config.w.globals.labels[config.dataPointIndex];
          setMinuteModalDay(dayjs(xAxisLabel));
          setMinuteModal(true);
        },
        dataPointMouseEnter: function (event) {
          event.target.style.cursor = 'pointer';
          // or
          event.fromElement.style.cursor = 'pointer';
        },
      },
    },
    xaxis: {
      categories: graphData.data.minutes.map((element: Minutes) => dayjs(element.date).format(dateFormatMinuteGraph)),
      labels: {
        formatter: (value) => {
          console.log(dayjs(value).format('DD.MM.yyyy'), dayjs(value).format('ddd'));
          return dayjs(value).format('ddd');
        },
      },
    },
    plotOptions: {
      bar: {
        distributed: true,
      },
    },
    colors: colors,
    legend: {
      show: false,
    },
    tooltip: {
      custom: ({ series, seriesIndex, dataPointIndex, w }) => {
        const data = series[seriesIndex][dataPointIndex];
        const date = w.globals.labels[dataPointIndex];
        return `<div class="${styles.toolltip}">
          <span class="${styles.title}">Date: ${dayjs(date).format('DD MMM')}</span><br/>
          <div class="${styles.minutes}">
            <span>Minutes:</span>
            <div>
              <span class="${styles.circle} ${selectClass(graphData.data.minutes[dataPointIndex])}"></span>
              <span>${data}</span>
            </div>
          </div>
        </div>`;
      },
    },
    annotations: annotations,
    dataLabels: {
      enabled: false,
    },
  };

  const chartSeries = [
    {
      name: 'Minutes: ',
      data: graphData.data.minutes.map((element: Minutes) => element.minutes),
    },
  ];

  return (
    <div className={styles.container}>
      <div className="mixed-chart">
        <div className={styles.graphHeader}>
          <div className={styles.titles}>
            <h2>Minute tracking</h2>
            <MinuteGraphLegend />
          </div>
          <div className={styles.dropdownContainer}>
            <Select
              defaultValue="last28days"
              value={selectedRange}
              className="select-after"
              style={{ width: '200px' }}
              allowClear={false}
              onChange={(value) => setSelectedRange(value)}
            >
              {getOptions(selectedYear).map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
            <Select
              value={selectedYear.toString()}
              disabled={selectedRange === 'last28days' || selectedRange === 'lastweek'}
              defaultValue={dayjs().year().toString()}
              className="select-after"
              style={{ width: '200px' }}
              allowClear={false}
              onChange={(value) => setSelectedYear(parseInt(value))}
            >
              {getYears().map((year) => (
                <Option key={year} value={year}>
                  {year}
                </Option>
              ))}
            </Select>
          </div>
        </div>
        <div className={styles.graph}>
          <Chart options={chartOptions} series={chartSeries} type="bar" height={400} />
        </div>
      </div>
      <Modal
        centered
        width={900}
        footer={null}
        open={minuteModal}
        title={`${minuteModalDay.format('dddd')} tracking period`}
        okText={null}
        onCancel={() => setMinuteModal(false)}
      >
        <MinuteModal referentDay={minuteModalDay} />
      </Modal>
    </div>
  );
};

export default MinuteGraph;
