import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Col, Row, Result } from 'antd';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASE_API_URL;

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const ResetPassword = () => {
  const { token, type } = useParams();

  const [form] = Form.useForm();

  const [successMessage, setSuccessMessage] = useState({
    visibility: false,
    message: '',
  });

  const [loadingResetReq, setLoadingResetReq] = useState(false);

  const onFinishReset = (values) => {
    // call this route with token from header, password,  password_confirmation and email ....
    let userData = {
      email: values.email,
      password: values.password,
      password_confirmation: values.password_confirmation,
      token,
      type,
    };
    setLoadingResetReq(true);
    axios.post(`${baseUrl}/admin-api/password/reset`, userData).then((res) => {
      const responseData = res.data;
      setLoadingResetReq(false);
      if (responseData.status === 'success') {
        setSuccessMessage({
          visibility: true,
          message: responseData.data.message,
        });
      } else {
        alert('Something went wrong while updating password');
      }
    });
  };

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const res = await axios.post(`${baseUrl}/admin-api/password/fetch/token`, { token: token, type: type });
        if (res.data.status === 'success') {
          //handle email field update
          form.setFieldsValue({
            email: res.data.data.email,
          });
        } else {
          //handle token error
          alert(res.data.data.error);
        }
      } catch (e) {
        console.log(e);
      }
    };

    fetchToken();
  }, [token]);

  return (
    <Row flex justify="center" align="middle" style={{ minHeight: '100vh', flexDirection: 'column' }}>
      {successMessage.visibility ? (
        <Result
          status="success"
          title="Password succesfully changed"
          subTitle={successMessage.message}
          extra={[
            <Button type="primary" key="back">
              <a href="/login">Go To login page</a>
            </Button>,
          ]}
        />
      ) : (
        <>
          <h1>Please reset your password:</h1>
          <Col span={12}>
            <Form {...layout} form={form} name="reset" onFinish={onFinishReset}>
              <Form.Item
                label="Email"
                name="email"
                disabled
                rules={[
                  {
                    message: 'Please input your email!',
                  },
                ]}
              >
                <Input disabled />
              </Form.Item>
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                  {
                    min: 6,
                    message: 'Password should be min 6 characters long!',
                  },
                  {
                    pattern: new RegExp(/^((?=.*[a-z])(?=.*[0-9]))/),
                    message: 'Password should be combination of characters and digits!',
                  },
                ]}
                hasFeedback
              >
                <Input.Password />
              </Form.Item>

              <Form.Item
                name="password_confirmation"
                label="Confirm Password"
                dependencies={['password']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Please confirm your password!',
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject('The two passwords that you entered do not match!');
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item {...tailLayout}>
                <Button htmlType="submit" type="primary" loading={loadingResetReq}>
                  Save
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </>
      )}
    </Row>
  );
};

export default ResetPassword;
