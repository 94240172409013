import { GET_BLOCKED_USERS } from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_BLOCKED_USERS:
      return {
        ...state,
        num_blocked_users: action.payload.number_of_blocked_users,
        number_of_missing_payloads: action.payload.number_of_missing_payloads,
        number_of_double_tickets_active: action.payload.number_of_double_tickets_active,
      };
    default:
      return state;
  }
};
