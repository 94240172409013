import React, { Suspense } from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';

import { PrivateRoute } from '../../hoc/ProtectedRoute';
import Loader from '../../Components/Loader/Loader';

const SkyAdmins = React.lazy(() => import('./SkyAdmins'));
const SkyCustomerC = React.lazy(() => import('./SkyCustomerC'));
const SkyJundgens = React.lazy(() => import('./SkyJundgens'));
const SkyLegal = React.lazy(() => import('./SkyLegal'));
const SuperAdmin = React.lazy(() => import('./SuperAdmin'));
const TmcAdmins = React.lazy(() => import('./TmcAdmins'));
const AdminPage = React.lazy(() => import('./AdminPage'));
const CachePage = React.lazy(() => import('./Cache/Cache'));

const Admin = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Suspense fallback={<Loader />}>
        <PrivateRoute exact path={`${path}`} component={AdminPage} />
        <PrivateRoute exact path={`${path}/super-admin`} component={SuperAdmin} />
        <PrivateRoute exact path={`${path}/tmc-admins`} component={TmcAdmins} />
        <PrivateRoute exact path={`${path}/sky-admin`} component={SkyAdmins} />
        <PrivateRoute exact path={`${path}/sky-legal`} component={SkyLegal} />
        <PrivateRoute exact path={`${path}/sky-jundgens`} component={SkyJundgens} />
        <PrivateRoute exact path={`${path}/sky-customer`} component={SkyCustomerC} />
        <PrivateRoute exact path={`${path}/cache`} component={CachePage} />
      </Suspense>
    </Switch>
  );
};

export default Admin;
