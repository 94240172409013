import React, { useReducer } from 'react';
import AuthContext from './authContext';
import authReducer from './authReducer';
import axios from 'axios';
import { AUTH_ERROR, CLEAR_ERRORS, LOGIN_FAIL, LOGIN_SUCCESS, LOGOUT, SET_LOADING, USER_LOADED } from '../types';
// import setTokenHeader from "../../utils/setTokenHeader";

const AuthState = (props) => {
  const initialState = {
    token: localStorage.getItem('token'),
    isAuthenticated: null,
    loading: true,
    user: null,
    error: null,
  };

  const baseUrl = process.env.REACT_APP_BASE_API_URL;
  const [state, dispatch] = useReducer(authReducer, initialState);

  const loadUser = async () => {
    // dispatch({
    //   type: SET_LOADING
    // })
    axios.interceptors.request.use(
      function (config) {
        const token = localStorage.getItem('token');
        if (token) {
          config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    try {
      const res = await axios.get(`${baseUrl}/admin-api/user/me`);
      dispatch({
        type: USER_LOADED,
        payload: res.data.data,
      });
    } catch (e) {
      dispatch({
        type: AUTH_ERROR,
      });
    }
  };

  // Login User
  const login = async (formData) => {
    // dispatch({
    //   type: SET_LOADING,
    // })
    const { email, password, 'g-recaptcha': recaptchaValue} = formData;

    try {
      const res = await axios.post(`${baseUrl}/admin-api/login`, { email, password, 'g-recaptcha': recaptchaValue });
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
      // await loadUser()
    } catch (error) {
      if (!error.response) {
        dispatch({
          type: AUTH_ERROR,
          payload: 'Something went wrong',
        });
      } else {
        console.log(error.response.data.message);
        dispatch({
          type: LOGIN_FAIL,
          payload: error.response.data.message,
        });
      }
    }
  };

  const logout = async () => {
    try {
      const res = await axios.get(`${baseUrl}/admin-api/logout`);
      if (res.data.status === 'success') {
        dispatch({ type: LOGOUT });
      }
    } catch (e) {}
  };

  const removeUser = () => {
    dispatch({ type: LOGOUT });
  };

  // Clear Errors
  const clearErrors = () => dispatch({ type: CLEAR_ERRORS });

  const setLoading = () => {
    dispatch({
      type: SET_LOADING,
    });
  };

  return (
    <AuthContext.Provider
      value={{
        token: state.token,
        isAuthenticated: state.isAuthenticated,
        loading: state.loading,
        user: state.user,
        error: state.error,
        login,
        logout,
        clearErrors,
        loadUser,
        removeUser,
        setLoading,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;
