import React, { FC, ReactElement } from 'react';
import { Menu } from 'antd';
import { pflegeMenuItems } from './pflegeMenuItems';
import { useLocation } from 'react-router-dom';

const PflegeLinks: FC = () => {
  const location = useLocation();
  const selectedKey =
    pflegeMenuItems.find((item) => {
      if (typeof item.label === 'string') {
        return false;
      } else if (
        React.isValidElement(item.label) &&
        (item.label as ReactElement<{ to: string }>).props.to === location.pathname
      ) {
        return true;
      }
      return false;
    })?.key || '1';

  return (
    <Menu
      mode="inline"
      defaultSelectedKeys={[selectedKey]}
      defaultOpenKeys={['sub1']}
      style={{ height: '100%', borderRight: 0 }}
      theme="dark"
      items={pflegeMenuItems}
    ></Menu>
  );
};

export default PflegeLinks;
