import { useQuery } from '@tanstack/react-query';
import { useApi } from 'src/hooks/useApi';
import { DoubleActiveTicketModel } from '../models/DoubleActiveTicketModel';

type DoubleActiveTicketQueryModel = {
  doubleActiveTicketData: DoubleActiveTicketModel[];
  isDoubleActiveTicketLoading: boolean;
};

const useDoubleActiveTicketQuery = (): DoubleActiveTicketQueryModel => {
  const { sendRequest } = useApi();

  const { data, isLoading } = useQuery({
    queryKey: ['double-active-ticket'],
    queryFn: async () => {
      const res: any = await sendRequest(`/double-active-products`);
      if (res.status === 200) {
        return res;
      }
    },
  });

  const doubleActiveTicketData = data?.data?.data;
  const isDoubleActiveTicketLoading = isLoading;

  return { doubleActiveTicketData, isDoubleActiveTicketLoading };
};

export default useDoubleActiveTicketQuery;
