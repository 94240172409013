import React, { useContext, useEffect, useState, useRef } from 'react';
import { Button, Col, Form, Input, Result, Row, Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import AuthContext from '../context/auth/authContext';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const LoginPage = () => {
  const history = useHistory();
  const { login, error, isAuthenticated } = useContext(AuthContext);
  const baseUrl = process.env.REACT_APP_BASE_API_URL;

  const [validationErr, setValidationErr] = useState(null);
  const [isRobot, setIsRobot] = useState(true);
  const recaptchaRef = useRef(null);

  const [filedType, setFieldType] = useState({
    login: true,
    reset: false,
  });

  const [successMessage, setSuccessMessage] = useState({
    visibility: false,
    message: '',
  });

  useEffect(() => {
    setValidationErr(error);

    return () => {
      setValidationErr(false);
    };
  }, [error]);

  const onFinish = async (values) => {
    setValidationErr(null);
    await login(values);
    recaptchaRef.current.reset();
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
  };

  const onFinishReset = async (values) => {
    const { email } = values;
    try {
      const res = await axios.post(`${baseUrl}/admin-api/password/email`, { email });
      if (res.data.status === 'success') {
        setFieldType({ login: false, reset: false });
        setSuccessMessage({
          visibility: true,
          message: res.data.message,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onChange = (value) => {
    setIsRobot(value ? false : true);
  };

  const renderResetPassword = () => {
    return (
      <Col span={12}>
        <Form
          {...layout}
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinishReset}
          onFinishFailed={onFinishFailed}
          onValuesChange={() => setValidationErr(null)}
        >
          <Form.Item
            label="Email"
            name="email"
            style={{ margin: '0' }}
            rules={[
              {
                required: true,
                message: 'Please input your email!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item {...tailLayout} style={{ margin: '0' }}>
            <Typography.Text type="danger">{validationErr}</Typography.Text>
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button htmlType="submit" type="primary">
              Submit
            </Button>
            <Button style={{ margin: '0 8px' }} onClick={() => setFieldType({ login: true, reset: false })}>
              Go Back
            </Button>
          </Form.Item>
        </Form>
      </Col>
    );
  };

  const renderLogin = () => {
    return (
      <Col span={12}>
        <Form
          {...layout}
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onValuesChange={() => setValidationErr(null)}
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: 'Please input your email!',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            style={{ marginBottom: '0' }}
            rules={[
              {
                required: true,
                message: 'Please input your password!',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item {...tailLayout} style={{ margin: '0' }}>
            <Typography.Text type="danger">{validationErr}</Typography.Text>
          </Form.Item>
          <Form.Item name="g-recaptcha" {...tailLayout}>
            <ReCAPTCHA ref={recaptchaRef} sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} onChange={onChange} />
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button onClick={() => setFieldType({ reset: true, login: false })} style={{ padding: 0 }} type="link">
              Forgot password
            </Button>
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button disabled={isRobot} block type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Col>
    );
  };

  const renderSuccess = () => {
    return (
      <Result
        status="success"
        title="Email sent"
        subTitle={successMessage.message}
        extra={[
          <Button
            type="primary"
            key="back"
            onClick={() => {
              setFieldType({
                login: true,
                reset: false,
              });
              setSuccessMessage({ visibility: false, message: '' });
            }}
          >
            Go Back
          </Button>,
        ]}
      />
    );
  };

  return (
    <Row flex="true" justify="center" align="middle" style={{ minHeight: '100vh' }}>
      {filedType.login && renderLogin()}
      {filedType.reset && renderResetPassword()}
      {successMessage.visibility && renderSuccess()}
    </Row>
  );
};

export default LoginPage;
