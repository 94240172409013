import React, { useEffect, useState, useContext } from 'react';
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  AutoComplete,
  Select,
  Result,
  Spin,
  message,
  Tabs,
  Divider,
  Space,
  DatePicker,
  Checkbox,
  Popconfirm,
} from 'antd';
import { useApi } from '../../hooks/useApi';
import { useParams, useHistory } from 'react-router-dom';
import AuthContext from '../../context/auth/authContext';
import FileBase64 from 'react-file-base64';

import moment from 'moment';
//TODO add search icon in follod up product

const { TabPane } = Tabs;
const { Option } = Select;
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const EditProduct = () => {
  const currentPage = localStorage.getItem('page');
  const { id } = useParams();
  const productsApi = useApi();
  const { sendRequest: apiProducts } = productsApi;
  const history = useHistory();
  const deleteApi = useApi();
  const { sendRequest: deleteProducts } = deleteApi;

  const authContext = useContext(AuthContext);
  const { logout } = authContext;

  const fetchApi = useApi();
  const { sendRequest } = fetchApi;

  const fetchAsset = useApi();
  const { sendRequest: assetFetch } = fetchAsset;

  const fetchPromo = useApi();
  const { sendRequest: promoFetch } = fetchPromo;

  const searchApi = useApi();
  const { sendRequest: apiSearch } = searchApi;

  const updateApi = useApi();
  const { sendRequest: updateProduct } = updateApi;

  const assetsApi = useApi();
  const { sendRequest: createAsset } = assetsApi;

  const createApi = useApi();
  const { sendRequest: createPromotion } = createApi;

  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const [searchQuery, setSearchQuery] = useState('');
  const [searchOptions, setSearchOptions] = useState('');
  const [searchOptionsId, setSearchOptionsId] = useState('');
  const [exparationType, setExparationType] = useState('');
  const [targetCountries, setTargetCountry] = useState('');
  const [expirationActiveKey, setExpirationActive] = useState('');
  const [translation, setTranslation] = useState('');
  const [previewImage, setPreviewImage] = useState('');
  const [previewImagePromotion, setPreviewImagePromotion] = useState('');
  const [image, setImage] = useState();
  const [promoImage, setPromoImage] = useState();
  const [assetId, setAssetId] = useState('');
  const [promoId, setPromoId] = useState('');
  const [followId, setFollowId] = useState('');
  const dateFormat = 'DD-MM-YYYY';
  const [item, setItem] = useState();

  const [recurring, setRecurring] = useState(false);
  const [maxCount, setMaxCount] = useState(false);
  const [currency, setCurrency] = useState('');
  const [previewSoft, setPreviewSoft] = useState('');
  const [previewHard, setPreviewHard] = useState('');
  const [country, setCountry] = useState(null);
  const [date, setDate] = useState({
    date: '',
    time: '00:00:00',
  });
  const [endDate, setEndDate] = useState({
    date: '',
    time: '12:00:00',
  });

  //TODO cleanup

  function onChange(value, dateString) {
    console.log('Selected Time: ', value);
    console.log('Formatted Selected Time: ', dateString);
  }

  function onOk(value) {
    console.log('onOk: ', value);
  }

  //TODO cleanup
  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseData = await createAsset('product/asset/create');
        const data = responseData.data.data;
        console.log(data.translationsLanguages);
        setTranslation(data.translationsLanguages);
      } catch (e) {
        console.log(e);
      }
    };

    fetchData();
  }, []);
  ///

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseData = await assetFetch(`product/asset/${assetId}/edit`);
        const data = responseData.data.data;
        const softData = data.productAsset.translations.filter((item) => item.hardness === 50)[0];
        const hardData = data.productAsset.translations.filter((item) => item.hardness === 75)[0];
        form1.setFieldsValue({
          title_soft: softData.title,
          title_hard: hardData.title,
          price_soft: softData.price_text,
          price_hard: hardData.price_text,
        });
        setPreviewHard(data.productAsset.hard_image);
        setPreviewSoft(data.productAsset.soft_image);
        setAssetId(data[0].id);
        // console.log(result)
      } catch (e) {
        console.log(e);
      }
    };
    if (assetId) {
      fetchData();
    }
  }, [assetId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseData = await promoFetch(`promotion-images?productId=${id}`);
        const data = responseData.data.data;
        setPromoId(data[0].id);
        console.log(data);
        // console.log(result)
      } catch (e) {
        console.log(e);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseData = await promoFetch(`promotion-images/${promoId ? promoId : ''}/edit`);
        const data = responseData.data.data;
        console.log(data);
        setPromoId(data.promotionImage.id);
        form2.setFieldsValue({
          title_de: data.promotionImage.translations[0].title,
          title_au: data.promotionImage.translations[1].title,
          description_au: data.promotionImage.translations[1].description,
          description_de: data.promotionImage.translations[0].description,
        });
        setPromoImage(data.promotionImage.image_url);
        // console.log(result)
      } catch (e) {
        console.log(e);
      }
    };

    promoId && fetchData();
  }, [promoId]);

  const fetchProducts = async () => {
    try {
      const responseData = await apiProducts(`product/${id}/edit`);
      const { expirationUnits, product: item } = responseData.data.data;
      // const arr = Object.values(expirationUnits)
      const unitsArray = Object.keys(expirationUnits).map((unitId) => ({
        id: Number(unitId),
        name: expirationUnits[Number(unitId)],
      }));

      if (item.begin_at_utc) {
        if (item.begin_at_utc === '0000-00-00 00:00:00') {
          setDate({
            date: null,
          });
        } else {
          setDate({
            date: item.begin_at_utc.split(' ')[0],
          });
        }
      }
      if (item.finish_at_utc) {
        setEndDate({
          date: item.finish_at_utc.split(' ')[0],
        });
      }

      // setRecurring(!!item.recurring)
      // setMaxCount(item.max_count > 0)
      setExparationType(unitsArray);
      setTargetCountry(item.target_countries);

      // setCurrency(item.currency)
      setAssetId(item.asset_id);

      setSearchOptionsId(item.follow_product_id);
      setRecurring(item.recurring);
      // setCountry(item.target_country_id)
      setTimeout(() => {
        form.setFieldsValue({
          platform_id: item.target_platforms.map((item) => item.id),
          max_count_checkbox: item.max_count !== 0,
          max_count: item.max_count,
          cancel_days_to_expiry_end: item.cancel_days_to_expiry_end,
          max_duration_count: item.max_duration_count,
          minimum_duration_count: item.minimum_duration_count,
          count: item.count,
          fe_visible: item.fe_visible,
          created_by: item.created_by,
          description: item.description,
          price: item.price,
          begin_at: item.begin_at_utc && item.begin_at_utc !== '0000-00-00 00:00:00' ? item.begin_at_utc : null,
          finish_at: item.finish_at_utc || null,
          expiration_unit: item.expiration_unit,
          expiration: item.expiration,
          expiration_month: item.expiration_month,
          follow_product_id: item.follow_product_name,
          follow_up_duration_count: item.follow_up_duration_count,
          id: item.id,
          recurring: item.recurring,
          max_count_per_user: item.max_count_per_user,
          modified_by: item.modified_by,
          name: item.name,
          target_country: item.target_countries.map((item) => item.id),
        });
      }, 20);
      // setDataSource(productList)
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    fetchProducts();
  }, [followId]);

  const searchProducts = async () => {
    try {
      const responseData = await apiSearch(`/product/find-by-name/${searchQuery}`);
      const searchData = responseData.data.data;
      const options = searchData.map((item) => [{ value: item.name, id: item.id }]);
      const arrays = options.reduce(function (a, b) {
        return a.concat(b);
      });

      setSearchOptions(arrays);
    } catch (e) {}
  };

  useEffect(() => {
    if (searchQuery) {
      searchProducts();
    }
  }, [searchQuery]);

  const handleChangeSearch = (e) => {
    setSearchQuery(e);
  };

  const goLogin = () => {
    logout();
  };
  const onFinish = async (values) => {
    let userData = {
      max_duration_count: values.max_duration_count || 0,
      product_price: values.price,
      fe_visible: values.fe_visible,
      recurring: values.recurring,
      name: values.name,
      platform_id: values.platform_id,
      description: values.description || null,
      begin_at_utc: values.begin_at || null,
      finish_at_utc: values.finish_at || null,
      expiration: Number(values.expiration) || null,
      expiration_unit: values.expiration_unit || expirationActiveKey.toString(),
      count: values.count,
      cancel_days_to_expiry_end: values.cancel_days_to_expiry_end,
      follow_product_id: searchOptionsId !== '' ? searchOptionsId : followId,
      max_count_per_user: values.max_count_per_user,
      follow_up_duration_count: values.follow_up_duration_count,
      minimum_duration_count: values.minimum_duration_count,
      target_country: values.target_country,
    };

    const responseData = await updateProduct(`product/${id}/edit`, {
      method: 'PATCH',
      body: userData,
    });
    if (responseData.status === 200) {
      message.success('Your product is succesfully updated');
    } else {
      message.error('There is some error while perform action');
    }
  };

  const onFinishAssets = async (values) => {
    let language_id;
    if (country === 1 || country === 2) {
      language_id = 2;
    } else {
      language_id = 1;
    }
    const softData = {
      hardness: 50,
      language_id: language_id,
      title: values.title_soft,
      price_text: values.price_soft,
    };

    const hardData = {
      hardness: 75,
      language_id: language_id,
      title: values.title_hard,
      price_text: values.price_hard,
    };
    const translations = [hardData, softData];

    const images = {
      soft: values.image_soft || [],
      hard: values.image_hard || [],
    };

    let userData = {
      translations: [...translations],
      images: images,
    };

    //TODO if assetId is null, go to create

    const urlUpdate = `product/asset/${assetId}/update`;
    const urlCreate = `product/${id}/asset/store`;

    const responseData = await createAsset(assetId != null ? urlUpdate : urlCreate, {
      method: assetId != null ? 'put' : 'post',
      body: userData,
    });

    if (responseData.status === 200) {
      message.success('Your product is succesfully updated');
    } else {
      message.error('There is some error while perform action');
    }
  };

  const getFiles = (files) => {
    form1.setFieldsValue({
      image: files,
    });
    setPreviewImage(files.base64);
  };

  const handleDelete = async (id) => {
    const responseData = await deleteProducts(`/product/${id}/delete`, {
      method: 'delete',
    });
    fetchProducts();
    history.push(`/products`);
  };

  const onFinishPromotion = async (values) => {
    let translate = translation.map((item, key) => ({
      language_id: item.id,
      title: values[`title_${item.name.slice(0, 2).toLowerCase()}`],
      description: values[`description_${item.name.slice(0, 2).toLowerCase()}`],
    }));
    let userData = {
      translations: [...translate],
      image: values.image_promotion,
    };
    const responseData = await createPromotion(`/promotion-images/${promoId}`, {
      method: 'put',
      body: userData,
    });

    if (responseData.status === 200 || responseData.status === 'success') {
      message.success('Your promotion image is succesfully updated');
      setTimeout(() => {
        history.push(`/products`);
      }, 1000);
      // setDisabled(false)
    } else {
      message.error('There is some error while perform action');
    }
  };

  const handleMaxCount = (e) => {
    setMaxCount(e.target.checked);
  };

  const handleRecurring = (e) => setRecurring(e.target.checked);

  const getFilesPromotion = (files) => {
    form2.setFieldsValue({
      image_promotion: files,
    });
    setPreviewImagePromotion(files.base64);
  };

  useEffect(() => form.resetFields(), [expirationActiveKey]);
  if (productsApi.isLoading || updateApi.isLoading) {
    return (
      <Row style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Spin size="large" />
      </Row>
    );
  } else if (productsApi.error || updateApi.error) {
    return (
      <Row style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Result
          title={updateApi?.error?.message || 'Something went wrong'}
          extra={
            <>
              <Button type="primary" onClick={() => history.push(assetFetch(`login`))}>
                Go to login
              </Button>
            </>
          }
        />
      </Row>
    );
  }
  console.log('startdate', date.date);

  function onChangeDateStart(date, dateString) {
    setDate({
      date: dateString,
    });
    form.setFieldsValue({
      begin_at: dateString,
    });
  }

  function onChangeDateEnd(date, dateString) {
    setEndDate({
      date: dateString,
    });
    form.setFieldsValue({
      finish_at: dateString,
    });
  }

  const getFilesSoft = (files) => {
    form1.setFieldsValue({
      image_soft: files,
    });
    setPreviewSoft(files.base64);
  };

  const getFilesHard = (files) => {
    form1.setFieldsValue({
      image_hard: files,
    });
    setPreviewHard(files.base64);
  };

  return (
    <div>
      <Tabs defaultActiveKey="1" forceRender={false}>
        <TabPane tab="Product Details" key="1">
          <Row style={{ marginTop: '30px' }}>
            <Col span={20}>
              <Form
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 10 }}
                layout="horizontal"
                form={form}
                // initialValues={{
                //   expiration_unit: expirationActiveKey.toString()
                // }}
                onFinish={onFinish}
              >
                <Form.Item label="Product Name:" name="name">
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Description:"
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: 'Description is required',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Platform"
                  name="platform_id"
                  rules={[
                    {
                      required: true,
                      message: 'Platform is required',
                    },
                  ]}
                >
                  <Select mode="multiple" disabled={true} placeholder="Please select a platform">
                    <Option value={1}>Web</Option>
                    <Option value={2}>Qbox</Option>
                  </Select>
                </Form.Item>

                <Form.Item label="FE visible" name="fe_visible" valuePropName="checked">
                  <Checkbox></Checkbox>
                </Form.Item>

                <Form.Item
                  name="begin_at"
                  label="Start Date"
                  rules={[
                    {
                      required: true,
                      message: 'Please select start date!',
                    },
                  ]}
                >
                  <Space direction="vertical">
                    <Row>
                      <Col span={24}>
                        {/*<DatePicker*/}
                        {/*  onChange={onChange}*/}
                        {/*  onOk={onChange}*/}
                        {/*  format="YYYY-MM-DD HH:mm:ss"*/}
                        {/*  value={moment(date.date, 'YYYY-MM-DD')}*/}
                        {/*  showTime={{value: moment(date.time, 'HH:mm:ss')}}*/}
                        {/*/>*/}

                        {date.date && (
                          <DatePicker
                            onChange={onChangeDateStart}
                            defaultValue={moment(date.date, 'DD-MM-YYYY')}
                            format={dateFormat}
                          />
                        )}
                        {!date.date && <DatePicker onChange={onChangeDateStart} format={dateFormat} />}

                        {/* {item && item.begin_at_utc !== '0000-00-00 00:00:00' ?*/}
                        {/* <DatePicker onChange={onChangeDate} defaultValue={moment(item.begin_at_utc, dateFormat)} format={dateFormat}/>:*/}
                        {/*<DatePicker onChange={onChangeDate} />}*/}
                      </Col>
                    </Row>
                  </Space>
                </Form.Item>

                <Form.Item label="Finish At:" name="finish_at">
                  <Space direction="vertical">
                    <Row>
                      <Col span={24}>
                        {endDate.date && (
                          <DatePicker
                            onChange={onChangeDateEnd}
                            defaultValue={moment(endDate.date, 'DD-MM-YYYY')}
                            format={dateFormat}
                          />
                        )}
                        {!endDate.date && <DatePicker onChange={onChangeDateEnd} format={dateFormat} />}

                        {/*  */}
                        {/*{item && item.finish_at_utc !==null?*/}
                        {/*  <DatePicker onChange={onChangeDateFinish} defaultValue={moment(item.finish_at_utc, dateFormat)} format={dateFormat} />*/}
                        {/*: <DatePicker onChange={onChangeDateFinish}/>}*/}
                      </Col>
                    </Row>
                  </Space>
                </Form.Item>

                <Form.Item label="Recurring" name="recurring" valuePropName="checked">
                  <Checkbox onChange={handleRecurring}></Checkbox>
                </Form.Item>

                {recurring && (
                  <React.Fragment>
                    <Divider />
                    <Form.Item label="Max duration count:" name="max_duration_count">
                      <Input />
                    </Form.Item>
                    <Form.Item label="Min duration count:" name="minimum_duration_count">
                      <Input />
                    </Form.Item>
                    <Divider />
                  </React.Fragment>
                )}

                <Form.Item
                  label="Expiration Unit:"
                  name="expiration_unit"
                  rules={[
                    {
                      required: true,
                      message: 'Expiration Unit is required',
                    },
                  ]}
                >
                  <Select placeholder="Please select expiration">
                    {exparationType.length > 0 &&
                      exparationType.map((item) => {
                        return <Option value={item.id}>{item.name}</Option>;
                      })}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Expiration:"
                  name="expiration"
                  rules={[
                    {
                      required: true,
                      message: 'Expiration is required',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item label="Count:" name="count">
                  <Input />
                </Form.Item>
                <Form.Item label="Cancel days to expiry:" name="cancel_days_to_expiry_end">
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Follow-up product:"
                  name="follow_product_id"
                  rules={[
                    {
                      required: true,
                      message: 'Follow-up product is required',
                    },
                  ]}
                >
                  <AutoComplete
                    options={searchOptions}
                    placeholder="start typing..."
                    onChange={(e) => handleChangeSearch(e)}
                    onSelect={(_, { id }) => setSearchOptionsId(id)}
                    filterOption={(inputValue, option) =>
                      option?.value.toUpperCase().indexOf(inputValue?.toUpperCase()) !== -1
                    }
                    value={searchOptionsId}
                  />
                </Form.Item>
                <Form.Item label="Max count per user:" name="max_count_per_user">
                  <Input />
                </Form.Item>
                <Form.Item label="Follow-up duration count:" name="follow_up_duration_count">
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Target Country:"
                  name="target_country"
                  rules={[
                    {
                      required: true,
                      message: 'Target Country is required',
                    },
                  ]}
                >
                  {targetCountries.length > 0 && (
                    <Select
                      disabled
                      mode="multiple"
                      onChange={(e) => setCountry(e)}
                      placeholder="Please select a target"
                    >
                      {targetCountries.map((country) => {
                        return <Option value={country.id}>{country.name}</Option>;
                      })}
                    </Select>
                  )}
                </Form.Item>
                <Row justify="space-between">
                  <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" onConfirm={handleDelete}>
                    <Button type="danger">Delete Product</Button>
                  </Popconfirm>

                  <Button type="primary" style={{ marginRight: '10px' }} htmlType="submit">
                    Update
                  </Button>
                </Row>
              </Form>
            </Col>
          </Row>
        </TabPane>
        <TabPane tab="Product Assets" key="2">
          <Row style={{ marginTop: '30px' }}>
            <Col span={24}>
              <Form
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 15 }}
                layout="horizontal"
                form={form1}
                labelAlign="left"
                onFinish={onFinishAssets}
              >
                <Row>
                  <Col span={12}>
                    <h2>Soft</h2>
                    <Form.Item label="Title" name="title_soft">
                      <Input />
                    </Form.Item>
                    <Form.Item label="Price Text" name="price_soft">
                      <TextArea />
                    </Form.Item>
                    <div>
                      <Form.Item label="Image" name="image_soft">
                        <FileBase64 multiple={false} onDone={getFilesSoft.bind(this)} />
                        {previewSoft && (
                          <img src={previewSoft} style={{ marginTop: '10px', width: '100%' }} alt="Soft Banner" />
                        )}
                      </Form.Item>
                    </div>
                  </Col>
                  <Col span={12}>
                    <h2>Hard</h2>
                    <Form.Item label="Title" name="title_hard">
                      <Input />
                    </Form.Item>
                    <Form.Item label="Price Text" name="price_hard">
                      <TextArea />
                    </Form.Item>
                    <div>
                      <Form.Item label="Image" name="image_hard">
                        <FileBase64 multiple={false} onDone={getFilesHard.bind(this)} />
                        {previewHard && (
                          <img src={previewHard} style={{ marginTop: '10px', width: '100%' }} alt="Soft Banner" />
                        )}
                      </Form.Item>
                    </div>
                  </Col>
                </Row>

                {/*  <Tabs defaultActiveKey="2" tabPosition="left"  style={{ height: 150 }} >*/}
                {/*  {translation && translation.map((item, index) => (*/}
                {/*    <TabPane tab={item.name} key={index}  forceRender={true}>*/}
                {/*      <Form.Item label={`Title ${item.name.toUpperCase().slice(0, 2)}`} name={`title_${item.name.slice(0, 2).toLowerCase()}`}*/}
                {/*          rules={[*/}
                {/*            {*/}
                {/*              required: true,*/}
                {/*              message: 'Title is required',*/}
                {/*            },*/}
                {/*          ]}>*/}
                {/*            <Input />*/}
                {/*      </Form.Item>*/}
                {/*      <Form.Item label={`Price text ${item.name.toUpperCase().slice(0, 2)}}`} name={`price_text_${item.name.slice(0, 2).toLowerCase()}`}*/}
                {/*          rules={[*/}
                {/*            {*/}
                {/*              required: true,*/}
                {/*              message: 'Price Text is required',*/}
                {/*            },*/}
                {/*          ]}>*/}
                {/*            <Input />*/}
                {/*      </Form.Item>*/}

                {/*    </TabPane>*/}
                {/*  ))*/}
                {/*}*/}

                {/*</Tabs>*/}
                <Divider />

                <Row justify="end">
                  <Button type="primary" style={{ marginRight: '10px' }} htmlType="submit">
                    Save
                  </Button>
                </Row>
              </Form>
            </Col>
          </Row>
          <Row style={{ marginTop: '30px' }}>
            <Col span={20}>
              <Form
                labelCol={{ span: 3 }}
                wrapperCol={{ span: 10 }}
                layout="horizontal"
                form={form1}
                labelAlign="left"
                onFinish={onFinishAssets}
              >
                <Tabs defaultActiveKey="0" tabPosition="left" style={{ height: 150 }}>
                  {translation &&
                    translation.map((item, index) => (
                      <TabPane tab={item.name} key={index} forceRender={true}>
                        <Form.Item
                          label={`Title ${item.name.toUpperCase().slice(0, 2)}`}
                          name={`title_${item.name.slice(0, 2).toLowerCase()}`}
                          rules={[
                            {
                              required: true,
                              message: 'Title is required',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          label={`Price text ${item.name.toUpperCase().slice(0, 2)}`}
                          name={`price_text_${item.name.slice(0, 2).toLowerCase()}`}
                          rules={[
                            {
                              required: true,
                              message: 'Price Text is required',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </TabPane>
                    ))}
                </Tabs>
                <Divider />
                <div style={{ paddingLeft: '152px' }}>
                  <Form.Item label="Image" name="image">
                    {image && <img src={image} alt="asset" style={{ maxWidth: '100%', marginBottom: '10px' }} />}
                    <FileBase64 multiple={false} onDone={getFiles.bind(this)} />
                    {previewImage && (
                      <img src={previewImage} style={{ marginTop: '10px', maxWidth: '100%' }} alt="Asset" />
                    )}
                  </Form.Item>
                </div>

                <Row justify="end">
                  <Button type="primary" style={{ marginRight: '10px' }} htmlType="submit">
                    Update
                  </Button>
                </Row>
              </Form>
            </Col>
          </Row>
        </TabPane>
        <TabPane tab="Promotion Image" key="3">
          <Row style={{ marginTop: '30px' }}>
            <Col span={20}>
              <Form
                labelCol={{ span: 3 }}
                wrapperCol={{ span: 10 }}
                layout="horizontal"
                form={form2}
                labelAlign="left"
                onFinish={onFinishPromotion}
              >
                <Tabs defaultActiveKey="2" tabPosition="left" style={{ height: 150 }}>
                  {translation &&
                    translation.map((item, index) => (
                      <TabPane tab={item.name} key={index} forceRender={true}>
                        <Form.Item
                          label={`Title ${item.name.toUpperCase().slice(0, 2)}`}
                          name={`title_${item.name.slice(0, 2).toLowerCase()}`}
                          rules={[
                            {
                              required: true,
                              message: 'Title is required',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>

                        <Form.Item
                          label={`Description ${item.name.toUpperCase().slice(0, 2)}}`}
                          name={`description_${item.name.slice(0, 2).toLowerCase()}`}
                          rules={[
                            {
                              required: true,
                              message: 'Description is required',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </TabPane>
                    ))}
                </Tabs>
                <Divider />
                <div style={{ paddingLeft: '124px' }}>
                  <Form.Item label="Image" name="image_promotion">
                    {promoImage && (
                      <img src={promoImage} alt="asset" style={{ maxWidth: '100%', marginBottom: '10px' }} />
                    )}
                    <FileBase64 multiple={false} onDone={getFilesPromotion.bind(this)} />
                    {previewImagePromotion && (
                      <img src={previewImagePromotion} style={{ marginTop: '10px', width: '100%' }} alt="promotion" />
                    )}
                  </Form.Item>
                </div>
                <Row justify="end">
                  <Button type="primary" style={{ marginRight: '10px' }} htmlType="submit">
                    Update
                  </Button>
                </Row>
              </Form>
            </Col>
          </Row>
        </TabPane>
      </Tabs>
    </div>
  );
};
export default EditProduct;
