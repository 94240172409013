import React from 'react';
import { Menu } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { DropboxOutlined, FileAddOutlined } from '@ant-design/icons';

const ProductsLinks = () => {
  return (
    <Menu
      mode="inline"
      defaultSelectedKeys={['1']}
      defaultOpenKeys={['sub1']}
      style={{ height: '100%', borderRight: 0 }}
      theme="dark"
    >
      <Menu.Item key="1" icon={<DropboxOutlined />}>
        All
        <Link to="/products" />
      </Menu.Item>
      <Menu.Item key="2" icon={<FileAddOutlined />}>
        Add New
        <Link to="/products/add-new" />
      </Menu.Item>
    </Menu>
  );
};

export default withRouter(ProductsLinks);
