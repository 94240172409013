import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import {
  FundViewOutlined,
  UnorderedListOutlined,
  ImportOutlined,
  CalendarOutlined,
  BankOutlined,
  TeamOutlined,
  VideoCameraOutlined,
  FileSearchOutlined,
  LineChartOutlined,
} from '@ant-design/icons';
import AuthContext from '../../context/auth/authContext';

const { SubMenu } = Menu;

const ContentLinks = () => {
  const authContext = useContext(AuthContext);
  const { user } = authContext;

  const location = useLocation();
  const selectedKey = location.pathname.split('/')[3]
    ? `${location.pathname.split('/')[2]}/${location.pathname.split('/')[3]}`
    : location.pathname.split('/')[2] || 'overview';

  const renderItems = () => {
    if (['sky-tech'].includes(user.role)) {
      return null;
    }
    if (
      (user && ['sky-admin', 'sky-legal', 'sky-jugen'].includes(user.role)) ||
      user.email === 'Felix.Witthueser@sky.at'
    ) {
      return (
        <>
          <Menu.Item key="overview" icon={<FundViewOutlined />}>
            Overview
            <Link to="/content/overview" />
          </Menu.Item>

          {user.role === 'sky-admin' && (
            <>
              <Menu.Item key="dashboard" icon={<LineChartOutlined />}>
                Dashboard <Link to="/content/dashboard" />
              </Menu.Item>
              <Menu.Item key="categories" icon={<UnorderedListOutlined />}>
                Categories
                <Link to="/content/categories" />
              </Menu.Item>

              <Menu.Item key="release-calendar" icon={<CalendarOutlined />}>
                Release Calendar
                <Link to="/content/release-calendar" />
              </Menu.Item>
            </>
          )}
        </>
      );
    } else {
      return (
        <React.Fragment>
          <Menu.Item key="overview" icon={<FundViewOutlined />}>
            Overview <Link to="/content/overview" />
          </Menu.Item>
          {user && ['sky-admin', 'tmc-super-admin', 'tmc-admin'].includes(user.role) && (
            <SubMenu key="dashboard" icon={<LineChartOutlined />} title="Dashboard">
              <Menu.Item key="dashboard/finance">
                <Link to="/content/dashboard/finance">Finance</Link>
              </Menu.Item>
              <Menu.Item key="dashboard/traffic">
                <Link to="/content/dashboard/traffic">Traffic</Link>
              </Menu.Item>
              <Menu.Item key="dashboard/errors">
                <Link to="/content/dashboard/errors">Errors</Link>
              </Menu.Item>
              <Menu.Item key="dashboard/minutes">
                <Link to="/content/dashboard/minutes">MInutes</Link>
              </Menu.Item>
            </SubMenu>
          )}
          <Menu.Item key="categories" icon={<UnorderedListOutlined />}>
            Categories
            <Link to="/content/categories" />
          </Menu.Item>
          <SubMenu key="import" icon={<ImportOutlined />} title="Import">
            <Menu.Item key="import/xml">
              XML <Link to="/content/import/xml" />
            </Menu.Item>
            <Menu.Item key="import/api">
              API <Link to="/content/import/api" />
            </Menu.Item>
            <Menu.Item key="import/manual">
              Manual <Link to="/content/import/manual" />
            </Menu.Item>
          </SubMenu>
          <Menu.Item key="release-calendar" icon={<CalendarOutlined />}>
            Release Calendar
            <Link to="/content/release-calendar" />
          </Menu.Item>

          <Menu.Item key="studios" icon={<VideoCameraOutlined />}>
            Studios
            <Link to="/content/studios" />
          </Menu.Item>

          <Menu.Item key="distributors" icon={<BankOutlined />}>
            Distributors
            <Link to="/content/distributors" />
          </Menu.Item>

          <Menu.Item key="actors" icon={<TeamOutlined />}>
            Actors
            <Link to="/content/actors" />
          </Menu.Item>

          <SubMenu key="screening" icon={<FileSearchOutlined />} title="Screening">
            <Menu.Item key="screening/raw">
              Raw
              <Link to="/content/screening/raw" />
            </Menu.Item>
            <Menu.Item key="screening/pools">
              Pools
              <Link to="/content/screening/pools" />
            </Menu.Item>
            <Menu.Item key="14">Months</Menu.Item>
            <Menu.Item key="15">Licences</Menu.Item>
            <Menu.Item key="16">Upload New</Menu.Item>
          </SubMenu>
        </React.Fragment>
      );
    }
  };

  return (
    <Menu
      mode="inline"
      defaultSelectedKeys={['1']}
      defaultOpenKeys={['sub1']}
      style={{ height: '100%', borderRight: 0 }}
      theme="dark"
      selectedKeys={[selectedKey]}
    >
      {renderItems()}
    </Menu>
  );
};

export default ContentLinks;
