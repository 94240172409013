import { useQuery } from '@tanstack/react-query';
import { useApi } from 'src/hooks/useApi';

type MinuteGraphRangeResponse = {
  graphData: GraphData;
  isLoading: boolean;
};

interface MinuteGraphBody {
  from: string;
  to: string;
}
export interface GraphData {
  data: Daum
  status: string
  message: string
}

export interface Minutes{
  id?: number
  year: number
  week: number
  day: number
  date: string
  minutes: number
  difference: string
}

export interface Daum {
  minutes: Minutes[]
}


const useMinuteGraphRangeQuery = (dateRange:MinuteGraphBody): MinuteGraphRangeResponse => {
  const { sendRequest } = useApi();
  const { data, isLoading } = useQuery({
    queryKey: ['minute_range',dateRange.from,dateRange.to],
    queryFn: async () => {
      const res: any = await sendRequest(`/content/statistics`,{body:dateRange,method:'POST'});
      if (res.status === 200) {
        return res;
      }
    },
  });

  const graphData = data?.data;

  return { graphData, isLoading };
};

export default useMinuteGraphRangeQuery;
