import React, { useContext } from 'react';
import { Result, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import AuthContext from '../context/auth/authContext';

const NotAllowed = () => {
  const history = useHistory();
  const { user } = useContext(AuthContext);

  const handleClick = () => {
    let path = '/';
    if (user) {
      path = user.role === 'sky-customer' ? '/users' : '/content/overview';
    }
    history.push(path);
  };

  return (
    <div>
      <Result
        status="403"
        title="403"
        subTitle="Sorry, you don't have permission to view this page"
        extra={
          <Button onClick={handleClick} type="primary">
            Back Home
          </Button>
        }
      />
      ,
    </div>
  );
};

export default NotAllowed;
