import React, { FC, useContext, useEffect } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { PrivateRoute } from './hoc/ProtectedRoute';
import { PublicRoute } from './hoc/PublicRoute';
import Products from './Pages/Products';
import Users from './Pages/Users';
import Admin from './Pages/Admin';
import Reporting from './Pages/Reporting';
import LoginPage from './Pages/LoginPage';
import EditProduct from './Pages/Products/EditProduct';
import ResetPassword from './Pages/ResetPassword';

import NotFound from './Pages/NotFound';
import AuthContext from './context/auth/authContext';
import Pflege from './Pages/Pflege';
import NotAllowed from './Pages/NotAllowed';
import DataContext from './context/data/dataContext';
import Cusers from './Pages/Users/Cusers/Cusers';
import MissingPayload from './Pages/MissingPayload/MissingPayload';
import Content from './Pages/Content';
import { DoubleActiveTicket } from './Pages/DoubleActiveTicket';

const App: FC = () => {
  const authContext = useContext(AuthContext);
  const { loadUser, isAuthenticated } = authContext;

  const dataContext = useContext(DataContext);
  const { getBlockedUsers } = dataContext;

  const element = document.getElementById('layout');
  const { pathname } = useLocation();
  if (element && (pathname.includes('dashboard') || pathname === '/content')) {
    element.className = 'gray-layout-background';
  } else if (element) {
    element.className = 'site-layout-background';
  }

  useEffect(() => {
    loadUser();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (isAuthenticated) {
      getBlockedUsers();
    }
    // eslint-disable-next-line
  }, [isAuthenticated]);

  return (
    <Switch>
      <Redirect exact from="/" to="/content" />
      <PrivateRoute mainView path={'/content'} component={Content} />
      <PrivateRoute mainView path={'/products'} component={Products} />
      <PrivateRoute mainView path={'/pflege'} component={Pflege} />
      <PrivateRoute mainView path={'/users'} component={Users} />
      <PrivateRoute mainView path={'/admin'} component={Admin} />
      <PrivateRoute mainView path={'/reporting'} component={Reporting} />
      <PrivateRoute mainView exact path={'/double-active-ticket'} component={DoubleActiveTicket} />

      <PrivateRoute mainView exact path={'/product/:id/edit'} component={EditProduct} />
      <PrivateRoute mainView exact path={'/missing-payloads'} component={MissingPayload} />
      <PrivateRoute mainView exact path={'/cusers'} component={Cusers} />

      <PublicRoute exact path={'/login'} component={LoginPage} />
      {/*Todo: make these routes public too*/}
      <Route exact path={'/reset/:token/:type'} component={ResetPassword} />
      <Route exact path={'/notAllowed'} component={NotAllowed} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default App;
