import React, { useReducer } from 'react';
import DataContext from './dataContext';
import dataReducer from './dataReducer';
import { GET_BLOCKED_USERS } from '../types';
import { useApi } from '../../hooks/useApi';
// import setTokenHeader from "../../utils/setTokenHeader";

const DataState = (props) => {
  const initialState = {
    num_blocked_users: null,
    number_of_missing_payloads: null,
    number_of_double_tickets_active: null,
  };

  const [state, dispatch] = useReducer(dataReducer, initialState);
  const api = useApi();
  const { sendRequest } = api;

  const getBlockedUsers = async () => {
    const response = await sendRequest('/notifications/number');
    if (response.status === 200) {
      dispatch({
        type: GET_BLOCKED_USERS,
        payload: response.data.data,
      });
    }
  };

  return (
    <DataContext.Provider
      value={{
        num_blocked_users: state.num_blocked_users,
        number_of_missing_payloads: state.number_of_missing_payloads,
        number_of_double_tickets_active: state.number_of_double_tickets_active,
        getBlockedUsers,
      }}
    >
      {props.children}
    </DataContext.Provider>
  );
};

export default DataState;
