import React, { FC, Suspense } from 'react';
import { PrivateRoute } from '../../hoc/ProtectedRoute';
import { Switch, useRouteMatch } from 'react-router-dom';
import Loader from '../../Components/Loader/Loader';

const StbPromotions = React.lazy(() => import('./StbPromotions/StbPromotions'));
const StbPromotionsCreate = React.lazy(() => import('./StbPromotions/components/Create/StbPromotionsCreate'));
const CreateBannerDrawerStb = React.lazy(() => import('./Banners/CreateBannerDrawerStb'));
const CreateBannerPflegeWeb = React.lazy(() => import('./Banners/CreateBannerPflegeWeb'));
const MaintenancePage = React.lazy(() => import('../Maintenance/Maintenance'));
const PflegeThemes = React.lazy(() => import('./PflegeThemes'));
const Teasers = React.lazy(() => import('./Teasers/TeasersPage'));
const PflegeBannersCom = React.lazy(() => import('./Banners/PflegeBannersCom'));
const PflegeBannersStb = React.lazy(() => import('./Banners/PflegeBannersStb'));
const ProductPromotions = React.lazy(() => import('./ProductPromotions/ProductPromotions'));
const ProductPromotionsCreate = React.lazy(() => import('./ProductPromotions/ProductPromotionsCreate'));
const Promotions = React.lazy(() => import('./Promotions'));
const PromotionContentEdit = React.lazy(() => import('./Promotions/PromotionContentEdit'));
const NewPromotionModal = React.lazy(() => import('./Promotions/PromotionContent'));
const PromotionFreeEdit = React.lazy(() => import('./Promotions/PromotionFreeEdit'));
const TransactionTool = React.lazy(() => import('./TransactionTool'));
const StaticPage = React.lazy(() => import('../Static/StaticPage'));

const Pflege: FC = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Suspense fallback={<Loader />}>
        <PrivateRoute exact path={`${path}`} component={PflegeThemes} />
        <PrivateRoute exact path={`${path}/teasers`} component={Teasers} />
        <PrivateRoute exact path={`${path}/promotions`} component={Promotions} />
        <PrivateRoute exact path={`${path}/transaction`} component={TransactionTool} />
        <PrivateRoute exact path={`${path}/banners/com`} component={PflegeBannersCom} />
        <PrivateRoute exact path={`${path}/banners/stb`} component={PflegeBannersStb} />
        <PrivateRoute exact path={`${path}/banners/create-web`} component={CreateBannerPflegeWeb} />
        <PrivateRoute exact path={`${path}/banners/create-stb`} component={CreateBannerDrawerStb} />
        <PrivateRoute exact path={`${path}/create-teaser`} component={Teasers} />
        <PrivateRoute exact path={`${path}/promotion`} component={NewPromotionModal} />
        <PrivateRoute exact path={`${path}/product-promotions`} component={ProductPromotions} />
        <PrivateRoute exact path={`${path}/stb-promotions`} component={StbPromotions} />
        <PrivateRoute exact path={`${path}/stb-promotions/create`} component={StbPromotionsCreate} />
        <PrivateRoute exact path={`${path}/pages/static`} component={StaticPage} />
        <PrivateRoute exact path={`${path}/pages/maintenance`} component={MaintenancePage} />
        <PrivateRoute exact path={`${path}/product-promotions/create`} component={ProductPromotionsCreate} />
        <PrivateRoute exact path={`${path}/product-promotions/:id`} component={ProductPromotionsCreate} />
        <PrivateRoute exact path={`${path}/promotions/content/:id`} component={PromotionContentEdit} />
        <PrivateRoute exact path={`${path}/promotions/free-credit/:id`} component={PromotionFreeEdit} />
      </Suspense>
    </Switch>
  );
};

export default Pflege;
