import React from 'react';
import { Menu } from 'antd';
import {Link, useLocation} from 'react-router-dom';
import { UserSwitchOutlined } from '@ant-design/icons';

const UsersLinks = () => {
  const location = useLocation();

  return (
    <Menu
      mode="inline"
      defaultSelectedKeys={[location.pathname === '/users' ? '1' : '2']}
      defaultOpenKeys={['sub1']}
      style={{ height: '100%', borderRight: 0 }}
      theme="dark"
    >
      <Menu.Item key="1" icon={<UserSwitchOutlined />}>
        Users <Link to="/users" />
      </Menu.Item>
      <Menu.Item key="2" icon={<UserSwitchOutlined />}>
        Exceeded Streaming minutes
        <Link to="/users/streaming/exceeded-minutes" />
      </Menu.Item>
    </Menu>
  );
};

export default UsersLinks;
