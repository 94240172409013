import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import MainView from '../views/MainView';
import AuthContext from '../context/auth/authContext';
import Loader from '../Components/Loader/Loader';

export const PrivateRoute = ({ mainView = false, component: Component, ...rest }) => {
  const authContext = useContext(AuthContext);
  const { isAuthenticated, loading } = authContext;
  if (isAuthenticated === null && loading === true) {
    return <Loader />;
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          mainView ? (
            <MainView>{Component && <Component {...props} />}</MainView>
          ) : (
            Component && <Component {...props} />
          )
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};
